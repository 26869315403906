<template>
  <el-form ref="form" :model="form" label-width="140px" class="elForm">
    <el-form-item label="优惠券名称：" required="true">
      <el-input v-model="form.coupon_name" placeholder="" style="width: 400px;"></el-input>
    </el-form-item>
    <el-form-item label="优惠券图片：" required="true" ref="upload"><ReadyUploadSource @getSource="val => (form.coupon_picture = val.path)" :path="form.coupon_picture" @removeThis="() => (form.coupon_picture = '')"></ReadyUploadSource>
    </el-form-item>
    <el-form-item label="每消费满：" required="true">
      <el-input  v-model="form.per_consume_full" type="number" step="0.01" style="width: 190px">
        <template slot="append">元</template>
      </el-input>
      减
      <el-input v-model="form.subtract" type="number" step="0.01" style="width: 190px">
        <template slot="append">元</template>
      </el-input>
    </el-form-item>
    <el-form-item label="兑换积分数：" required="true">
      <el-input v-model="form.integral" style="width: 400px">
        <template slot="append">积分</template>
      </el-input>
    </el-form-item>
    <el-form-item label="领券时间：" required="true">
      <el-date-picker style="width: 400px"
        v-model="form.get_start_time"
        type="daterange"
        range-separator="~"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="timestamp"
      ></el-date-picker>
    </el-form-item>
    <el-form-item label="有效期类型：" required="true">
      <el-radio-group v-model="form.time_type">
        <el-radio :label="1">固定时间</el-radio>
        <el-radio :label="2">领取N天内</el-radio>
      </el-radio-group>
      <br/>
      <el-date-picker v-if="form.time_type == 1" style="width: 400px"
        v-model="form.start_time"
        type="daterange"
        range-separator="~"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="timestamp"
      ></el-date-picker>
      <el-input v-if="form.time_type == 2" v-model="form.days" type="number" style="width: 400px"><template slot="append">天</template></el-input>
    </el-form-item>
    <el-form-item label="状态：" required="true">
      <el-radio-group v-model="form.status">
        <el-radio :label="1">正常</el-radio>
        <el-radio :label="-1">停用</el-radio>
      </el-radio-group>
    </el-form-item>
    <Preservation @preservation="preservation"></Preservation>
  </el-form>
</template>

<script>
import _ from 'lodash';
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';

export default {
  components: {
    Preservation,
    ReadyUploadSource,
  },
  data() {
    return {
      form: {
        coupon_name: '',
        coupon_picture: '',
        per_consume_full: 0,
        subtract: 0,
        integral: 0,
        get_start_time: '',
        get_end_time: '',
        time_type: 1,
        start_time: '',
        end_time: '',
        days: 0,
        status: 1,
      },
    };
  },
  methods: {
    // 调用接口时检测数据是否合法
    checkDataLegitimacy () {
      if (!this.form.coupon_name) {
        this.$message.warning('请填写优惠券名称');
        this.activeTabs = 'one';
        return false;
      }
      if (!this.form.coupon_picture) {
        this.$message.warning('请上传优惠券图片');
        this.activeTabs = 'one';
        return false;
      }
      if (!this.form.per_consume_full) {
        this.$message.warning('请填写每消费满');
        this.activeTabs = 'one';
        return false;
      }
      if (!this.form.subtract) {
        this.$message.warning('请填写减');
        this.activeTabs = 'one';
        return false;
      }
      if (!this.form.integral) {
        this.$message.warning('请填写兑换积分数');
        this.activeTabs = 'one';
        return false;
      }
      if (this.form.get_start_time.length == 0) {
        this.$message.warning('请选择领券时间');
        this.activeTabs = 'one';
        return false;
      }
      if (this.form.time_type == 1 && this.form.start_time.length == 0) {
        this.$message.warning('请选择有效时间');
        this.activeTabs = 'one';
        return false;
      }
      if (this.form.time_type == 2 && !this.form.days) {
        this.$message.warning('请填写有效天数');
        this.activeTabs = 'one';
        return false;
      }
    },
    preservation() {
      let checkRes = this.checkDataLegitimacy();
      if (checkRes === false) return;
      let form = _.cloneDeep(this.form);
      form.get_end_time = form.get_start_time[1] / 1000;
      form.get_start_time = form.get_start_time[0] / 1000;
      if(form.start_time){
        form.end_time = form.start_time[1] / 1000;
        form.start_time = form.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.repair.Coupon.add, form).then(res => {
        if (res.code == 0) {
          this.$message.success('添加成功');
          this.$router.push('/marketing/repairCouponList');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.elForm {
  background-color: #fff;
  padding: 30px;
}
</style>
